import React from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-reveal';
import Layout from '../../components/layout';
import kenafFlour from '../../assets/images/kenafFlour.jpeg';
import kenafOil from '../../assets/images/kenafOil.jpeg';
import kenafFiber from '../../assets/images/kenafFiber.jpg';
const Fiber = (props) => (
	<Layout>
		<Helmet>
			<title>Allied Fibres Project</title>
			<meta name='description' content='Kenaf Info' />
		</Helmet>
		<Fade duration={2500}>
			<div id='main' className='wrapper style1'>
				<div className='container'>
					<header className='major'>
						<h2>Allied Fibres Program</h2>
						<p>
							Our Allied Fibres Program is a multi-year project where the main
							objective would be for SGP Foods to become a major supplier of
							allied fibre-based products worldwide. The allied fibre crop
							contains a multitude of uses ranging from being a food crop,
							cosmetics ingredient and building materials. In addition to having
							a large variety of uses, the allied fibre crop is extremely
							sustainable and environmentally friendly, with efficient land
							usage and low waste production.
							<br />
						</p>
					</header>
					<div className='row gtr-150'>
						<div className='col-12 col-12-medium col-12-small col-12-xsmall'>
							<p style={{ textAlign: `center` }}>
								<b>
									This aligns with SGP Foods’ goals and mission of providing
									high quality, sustainable solutions. We strongly believe that
									allied fibre is the crop for the future amidst our current
									environmental climate and we fully intend to harness its
									sustainable yielding potential in this untapped market.With
									our Allied Fibres Program, we control most of the allied fibre
									production process, from cultivating and harvesting the allied
									fibre crops to processing allied fibres into our various
									secondary products. We have introduced SGP Foods’ own
									innovative, high-quality, proprietary technologies and methods
									into each step of the process with aims of raising efficiency
									and quality of the final product.
								</b>
							</p>
						</div>
						<div className='col-12 col-12-medium col-12-small col-12-xsmall'>
							<p style={{ textAlign: `center` }}>
								<b>
									Our primary products derived from the Allied Fibres Program
									will be allied fibre flour, oil, and bio-composites.
								</b>
							</p>
						</div>
						<br />
						<div className='col-4 col-6-medium col-12-small col-12-xsmall'>
							<img
								alt=''
								src={kenafFlour}
								style={{ width: `400px`, height: `300px` }}
							></img>
						</div>
						<div className='col-4 col-6-medium col-12-small col-12-xsmall'>
							<img
								alt=''
								src={kenafOil}
								style={{ width: `400px`, height: `300px` }}
							></img>
						</div>
						<div className='col-4 col-6-medium col-12-small col-12-xsmall'>
							<img
								alt=''
								src={kenafFiber}
								style={{ width: `400px`, height: `300px` }}
							></img>
						</div>
						<div className='col-12 col-6-medium col-12-small col-12-xsmall'>
							<hr />
						</div>
						<div className='col-12 col-12-medium col-12-small col-12-xsmall'>
							<b>
								These primary products have properties that matches or even
								exceeds conventional sources whilst being much more sustainable
								and cost-effective. From these primary products, we will produce
								our secondary value-added products. They include: <br />
							</b>
							<br />
							<ul>
								<li>
									<b>Mayonnaise</b>
								</li>
								<li>
									<b>Tofu</b>
								</li>
								<li>
									<b>Cosmetics and Medicinal Compounds</b>
								</li>
								<li>
									<b>Biofuel</b>
								</li>
							</ul>
						</div>
						<div className='col-12 col-6-medium col-12-small col-12-xsmall'>
							<b>
								We are extremely confident of our Allied Fibres Program, where
								the process has been meticulously crafted and conceptualized by
								seasoned professionals and individuals with strong expertise in
								the agricultural and F&B industry. We strongly believe that the
								final allied fibres-based products reflect the quality and
								effort that has been incorporated in its production.{' '}
							</b>
						</div>
					</div>
				</div>
			</div>
		</Fade>
	</Layout>
);

export default Fiber;
